import { Callout1 } from '@/styles/typography'
import { Fragment } from 'react'
import styled from 'styled-components'
import { type FooterData } from '@/components/Footers/MarketingFooter/types'
import { MarketingFooterLinks } from '@/components/Footers/MarketingFooter/MarketingFooterLinks'
import { Column } from '@shipt/design-system-layouts'

type Props = {
  section: FooterData['sections'][number]
}

export const DesktopFooterLinksColumn = ({ section }: Props) => {
  return (
    <div>
      <SectionTitleWrapper aria-label={section.title}>
        <SectionTitle>{section.title}</SectionTitle>
      </SectionTitleWrapper>

      <SectionLinks spacing="xs">
        <MarketingFooterLinks links={section.links} />
      </SectionLinks>
      {section?.subSections?.map((subSection) => (
        <Fragment key={subSection.title}>
          <SubSectionTitleWrapper aria-label={subSection.title}>
            <SectionTitle>{subSection.title}</SectionTitle>
          </SubSectionTitleWrapper>
          <SectionLinks spacing="xs">
            <MarketingFooterLinks links={subSection.links} />
          </SectionLinks>
        </Fragment>
      ))}
    </div>
  )
}

const SectionLinks = styled(Column)`
  max-width: 10.6875rem;
`

const SectionTitleWrapper = styled.div`
  display: flex;
`
const SubSectionTitleWrapper = styled.div`
  display: flex;
  margin-top: 1.5rem;
`

const SectionTitle = styled(Callout1)`
  flex-grow: 1;
  text-transform: uppercase;
  margin-bottom: 8px;
`
