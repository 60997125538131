import styled from 'styled-components'
import { DesktopFooterLinksColumn } from '@/components/Footers/MarketingFooter/DesktopFooterLinksColumn'
import { MobileFooterAccordion } from '@/components/Footers/MarketingFooter/MobileFooterAccordion'
import { screenSizes } from '@shipt/design-system-themes'
import {
  footerData,
  shopperFooterSections,
} from '@/components/Footers/MarketingFooter/constants'
import { type FooterContentType } from '@/components/Footers/MarketingFooter/types'

type Props = { contentType?: FooterContentType }

export const FooterSections = ({ contentType }: Props) => {
  const sections =
    contentType === 'shopper' ? shopperFooterSections : footerData.sections
  return (
    <>
      <DesktopFooterLinks>
        {sections.map((section) => (
          <DesktopFooterLinksColumn key={section.title} section={section} />
        ))}
      </DesktopFooterLinks>
      <MobileFooterAccordion sections={sections} />
    </>
  )
}

const DesktopFooterLinks = styled.div`
  display: none;

  @media ${screenSizes.tablet} {
    display: flex;
    gap: 1rem;
    margin-bottom: 5rem;
    flex-grow: 1;
    justify-content: space-evenly;
  }

  @media ${screenSizes.lgDesktop} {
    margin-bottom: 5.5rem;
  }
`
